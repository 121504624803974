<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  size="12"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId"
                       @change='areaChange'
                       filterable
                       placeholder=""
                       size="10">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.streetId"
                       filterable
                       size="15"
                       placeholder="请选择"
                       @change="$refs.parkInput.setValue('');formInline.operationId = ''">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="1"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
              <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm"
              >清空</el-button
            > -->
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" icon="el-icon-edit" @click="dialogShow"
                >大额欠费提醒设置
              </el-button>
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        class="dialogVisible"
        title="欠费提醒配置"
        :visible.sync="dialogVisible"
        width="450px"
      >
        <el-form
          ref="form"
          @submit.native.prevent="submit"
          :rules="rules"
          :model="arrearsForm"
          label-width="120px"
          style="width: 80%"
        >
          <el-form-item label="欠费提醒金额" prop="debtMoney">
            <el-input
              width="120px"
              maxlength="7"
              placeholder="请输入欠费提醒金额"
              type="text"
              v-model="arrearsForm.debtMoney"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex, onlyNumber } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";

export default {
  name: "arrearsRecordTotal",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输十一个字符"));
      }
    };
    return {
      defalutDate: [],
      onlyNumber,
      rules: {
        debtMoney: [
          { required: true, message: "欠费提醒金额不能为空" },
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                return callback(new Error("必须大于0"));
              }
              this.arrearsForm.debtMoney = this.onlyNumber(value);
              callback();
            },
            trigger: "change",
          },
        ],
      },
      dialogVisible: false,
      arrearsForm: {
        debtMoney: "",
      },
      state2: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      roleList: [],
      plateList: [],
      merchantList: [],
      berthList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          label: "编号",
          prop: "index",
          width: "100",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.arrears"),
          width: "100",
          formatter: (row) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "alarmTime",
          label: "预警时间",
          width: "",
        },
        {
          prop: "berthCode",
          label: "预警泊位",
          width: "",
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        areaId: "",
        operationId: "",
        parkId: "",
        plateNumber: "",
        carId: "",
      },
      exportData: {},
      areaIds: "",
    };
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号",
        },
      ];
    },
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    dialogShow() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.getDebtMoney();
      });
    },
    getDebtMoney() {
      this.$axios.get("/acb/2.0/bigDebtRule/getLatestRecord").then((res) => {
        this.arrearsForm.debtMoney = res.value.debtMoney
          ? (res.value.debtMoney / 100).toFixed(2)
          : "";
      });
    },
    getAlarm() {
      this.$axios.get("/acb/2.0/bigDebtAlarm/delete").then((res) => {});
    },
    submit() {
      this.$refs.form.validate((v) => {
        if (v) {
          this.dialogVisible = false;
          let debtMoney = parseFloat((this.arrearsForm.debtMoney * 100).toPrecision(12));
          this.$axios
            .post("/acb/2.0/bigDebtRule/create", {
              data: {
                debtMoney,
              },
            })
            .then(() => {
              this.$message.success("操作成功！");
            });
        }
      });
    },
    resetForm() {
      this.formInline = {
        areaId: "",
        operationId: "",
        parkId: "",
        plateNumber: "",
      };
      this.formInline.operationId = "";
      this.formInline.parkName = "";
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      this.getChildrenArea(item);
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        // for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
        //   this.parkCommercialStatus.push(res.value.parkCommercialStatus[i])
        // }
      });
    },
    getChildrenArea() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: this.formInline.areaId,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
        });
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.carId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            slaveRelations: "0,1",
            dataSource: 1,
            operationId: this.formInline.operationId,
            areaIds: this.formInline.areaId,
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 搜索
    searchData() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.carId = "";
      }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/bigDebtAlarm/query",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId: areaId,
          operationId: this.formInline.operationId,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          plateNumber: this.formInline.plateNumber,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
      };
      this.loading = true;
      this.$refs.parkInput.setShowVal(this.formInline.parkName);
      this.$request(opt);
    },
  },
  components: {
    myComponent,
  },
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData();
    this.getParkSpinner();
    // this.getMerchant();
    // this.getPay();
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    this.searchData();
    this.getAlarm();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
